@import 'vars.module.scss';
@import '~@ems/client-design-system/scss/styles.scss';

html,
body,
#root {
  height: 100%;
}

:root {
  --severity-width: min-content;
  --severity-padding: 32px;
  --severity-thin-padding: 20px;
  --candidates-width: minmax(125px, max-content);
  --candidates-overflow: default;
  --status-width: minmax(90px, max-content);
  --status-thin-width: minmax(65px, max-content);
}

@import './layout';
@import './page-header';
@import './dashboard';
@import './operations';
@import './infringements';
@import './noiseEvents';
@import './reports';
@import './settings';
@import './settings_fleet_mix';
@import './settings_time_in_modes';
@import './userHelp';
@import './complaints';
@import './airtrak';
@import './settings_aircraft_mappings';
@import './settings_load_factors';
@import './settings_fuel_types';
@import './settings_manage_configurations';
@import './settings_general';
@import './table';