body {
  padding: 0;
  margin: 0;
}

.signin-wrapper {
  font-family: 'Inter', sans-serif;
  transition: all 1s linear;
  visibility: visible !important;
  opacity: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-image: url('https://d3nvesd3xvdinx.cloudfront.net/auth/background.jpg');

  .sign-in-box {
    width: 470px;
    display: block;
    background: #fff;
    padding: 40px;
    border-color: #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    h2 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #333;
      padding: 20px 0;
    }

    .sitename {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.1;
      color: #333;
    }

    .buttons {
      margin: 30px 0 20px 0;
    }

    button.standard {
      float: right;
      color: #fff;
      background-color: #0b6bf2;
      border: 1px solid transparent;
      box-shadow: none;
      font-family: Arial;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      vertical-align: middle;
      text-align: left;
      border-radius: 3px;
      cursor: pointer;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 7rem;
      padding: 0 2rem;
      line-height: 2.5em;
    }

    .logo {
      width: 242.5px;
      height: 50px;
      display: block;
      background-size: 100% 100%;
      background-image: url('https://d3nvesd3xvdinx.cloudfront.net/auth/logo.png');
    }

    .error {
      color: red;
      margin: 0 0 10px;
    }
  }
}
