.complaints-table {
  grid-template-columns:
    [selector] 64px
    [complainerName] minmax(140px, max-content)
    [time] minmax(120px, max-content)
    [reason] minmax(130px, max-content)
    [complainerPostcode] minmax(100px, max-content)
    [correlated] minmax(min-content, auto)
    [status] minmax(100px, max-content);
}

.complaints-regular-table {
  @extend .complaints-table;

  .table--slim & {
    @extend .complaints-table;
  }

  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .hover-only {
    opacity: 0;
  }

  .no-rule_link {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
  }

  .rule_link {
    font-family: 'Inter', sans-serif;
    color: $brand-darkened;
    text-decoration: none;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    font-weight: 600;
  }

  .cell-type {
    &--time {
      font-family: 'Inter', sans-serif;
      color: #637381;

      .table_cell--item {
        width: 100%;
      }
    }

    &--reason,
    &--complainerPostcode {
      font-family: 'Inter', sans-serif;
      color: #31393c;

      .table_cell--item {
        width: 100%;
      }
    }

    &--displayName {
      .table_cell--item {
        width: 100%;
      }
    }

    &--correlated {
      .table_cell--item {
        display: flex;
        flex-direction: row;

        :not(:last-child) {
          margin-right: 4px;
        }
      }
    }
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

}

.link_button {
  color: $ui-01;
  text-decoration: none;
}

.complaints {
  &_layout {
    .pointer {
      cursor: pointer;
    }

    .card {
      &.address-list-card {
        padding: 0;
      }

      &.inquirer-card,
      &.inquirer-view-card,
      &.inquiry-card,
      &.inquiry-view-card {
        padding: $spacing-2xl rem(86px) $spacing-2xl $spacing-3xl;

        div {
          padding: 0;
        }
      }

      &.inquiry-card {
        padding: $spacing-2xl $spacing-3xl $spacing-lg rem(66px);
      }
    }

    .field-details {
      padding: 0;
      margin-bottom: rem(32px);

      &:last-child {
        margin-bottom: rem(0px);
      }
    }

    .edit-container {
      cursor: pointer;

      &__text {
        font-size: 14px;
        margin-right: $spacing-2xs;
        text-decoration: underline;
        color: $brand-01;
      }
    }

    .layout {
      &_split {
        margin: 0;

        .comment-container {
          padding-bottom: $spacing-xl;
        }
      }
    }

    .no_link {
      text-decoration: none;
      color: inherit;
      color: $text-02;
    }

    .row {
      display: flex;

      &-space-between {
        justify-content: space-between;
      }

      &.align-center {
        align-items: center;
      }

      &.justify-content-center {
        justify-content: center;
      }
    }

    .col-md-1 {
      width: 8.3333;
    }

    .col-md-2 {
      width: 16.6666%;
    }

    .col-md-3 {
      width: 25%;
    }

    .col-md-4 {
      width: 33.3333%;
    }

    .col-md-5 {
      width: 41.6667%;
    }

    .col-md-6 {
      width: 50%;
    }

    .col-md-7 {
      width: 58.3333%;
    }

    .col-md-8 {
      width: 66.6666%;
    }

    .col-md-9 {
      width: 75%;
    }

    .col-md-10 {
      width: 83.3333%;
    }

    .col-md-11 {
      width: 91.6666%;
    }

    .col-md-12 {
      width: 100%;
    }

    .form-group {
      margin-right: 14px;

      &__label {
        position: relative;

        .rule_link {
          cursor: pointer;
          position: absolute;
          top: 3px;
          right: 0;
          color: #292f33;
          font-size: 14px;
          z-index: 2;
          border-bottom: 1px solid #292f33;
          line-height: 100%;
          font-weight: normal;
          font-stretch: normal;
        }
      }

      &__form-field {
        input:focus,
        input:active {
          border: 1px solid $brand-01;
          outline: none;
          box-shadow: 0 0 0 1px $brand-01, 0 0 0 0 transparent;
        }
      }

      .auto-search {
        height: 36px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .address-field {
      position: relative;

      .rule_link {
        cursor: pointer;
        position: absolute;
        top: 3px;
        right: 0;
        color: #292f33;
        font-size: 14px;
        z-index: 2;
        border-bottom: 1px solid #292f33;
        line-height: 100%;
        font-weight: normal;
        font-stretch: normal;
      }
    }

    .bp3-checkbox {
      padding-left: 0;
    }

    .dropdown {
      &--with-margin {
        margin-bottom: $spacing-xl;
      }
    }

    .card {
      margin-bottom: $layout-sm;
    }

    .inquirer_card {
      margin-bottom: $layout-xs;
    }

    .bp3-control-indicator {
      margin-right: $spacing-baseline;
    }

    .add-icon {
      margin-right: 6px;
      background-color: $brand-01;
      width: 18px;
      height: 18px;
      overflow: hidden;
      border-radius: 50%;
      transform: rotate(45deg);
    }

    .multioptions-button-container {
      margin-right: $spacing-md;
    }

    .complaints-datetime {
      margin-right: 40px;

      .bp3-button {
        border: 1px solid $ui-03;
        min-width: 176px !important;
      }

      &__label {
        color: $text-01;
        margin-bottom: 10px;
        display: block;
        @include font-size('14');
      }
    }

    .btn-save {
      border: 1px solid $ui-04 !important;
      color: $text-01 !important;
      background-color: $ui-01 !important;
    }

    .edit-form-buttons {
      margin-bottom: $spacing-xl;
    }
  }
}

.complainer-option {
  .highlighter {
    color: $text-01;
    font-weight: 600;
  }

  &__full-name {
    color: $ui-05;
    font-size: 14px;
    margin-bottom: $spacing-2xs;

    p {
      margin-right: $spacing-2xs;
    }
  }

  &__address {
    color: $ui-04;
    font-size: 10px;
    margin-bottom: $spacing-2xs;
  }

  &__phone-number {
    color: $ui-04;
    font-size: 10px;
  }
}

.flight-confirm-panel {
  height: 108px;
  background-color: rgba($ui-01, 0.74);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  &_title {
    font-weight: 600;
    display: flex;
    justify-content: center;
  }

  &_description {
    display: flex;
    justify-content: center;
  }

  &_confirm {
    opacity: 1;
  }
}

.cancel-save-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px;
}

.flight-correlation {
  .edit-flight {
    display: flex;
    justify-content: flex-end;

    .link-to-page {
      text-decoration: none;
      color: #fff;
    }
  }
}

.complaints-terms-and-conditions {
  text-decoration: underline;
}

.overlay--complaints-terms-conditions {
  width: 500px;
  max-height: 80%;
  display: flex;
  flex-direction: column;

  .overlay_content {
    overflow-y: scroll;
  }
}
