@import 'vars.module.scss';
$map-skeleton-bg: $ui-03 !default;

@mixin collapse-correlated-pills {
  .operations-correlated {
    .pill_text {
      display: none;
    }
    .pill_icon {
      margin: 0;
    }
  }
}

.operations-table {
  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .hover-only {
    opacity: 0;
  }

  .rule_link {
    color: $brand-darkened;
    text-decoration: none;

    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: 300px;
  }

  .cell-type {
    &--record-title {
      font-weight: bold;
    }

    &--aircraft,
    &--runway {
      overflow: visible;
      text-align: center;
      display: flex;
      justify-content: center;

      & .grid-cell--title {
        overflow: hidden;
        justify-content: center;
      }

      & .grid-cell--item {
        text-align: center;
        transform: translateX(-30%);
        overflow: visible;
      }
    }

    &--time,
    &--pcaTime {
      color: $time-color;
    }

    &--diagonal,
    &--horizontal,
    &--altitude,
    &--pcaTime {
      overflow-x: hidden;
    }

    &--correlated {
      overflow-x: hidden;

      .table_cell--item {
        display: flex;
        flex-direction: row;
      }
    }
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  .operation_pill {
    margin-right: 6px;
  }

  .operation_pill:last-child {
    margin-right: 0;
  }

  &.feature--operationtags {
    .operatorcategory--military {
      .cell-type--aircraft {
        svg,
        svg.mosaic-icon {
          fill: $brand-darkened;
        }
      }
    }
  }

  .operations-pcaTime,
  .operations-displayDiag,
  .operations-displayHoriz,
  .operations-displayAlt {
    overflow: hidden;
  }

  .operations-correlated .pill_icon {
    margin: 0 4px 0 0;
  }

  // .operations-displayRunwayName .sort-header {
  //   padding-left: 20px;
  // }

  .operations-displayFlag {
    .#{$prefix}-skeleton-text {
      max-width: 1.5rem;
    }
  }

  .operations-displayRunwayName,
  .operations-displayCategory {
    .#{$prefix}-skeleton-text {
      max-width: 3rem;
    }
    .sort-icon {
      left: 100%;
    }
  }

  .operations-aircraftType,
  .operations-airportId,
  .operations-remoteAirportId {
    .#{$prefix}-skeleton-text {
      max-width: 4rem;
    }
  }
}

.table--slim {
  .operations-table {
    th.operations-displayCategory {
      overflow: hidden;
      padding-left: 0px;
    }
  }
}

.page-count {
  color: $text-02;
  font-size: $spacing-sm;
}

#deckgl-wrapper {
  width: 100%;
  height: 100%;
}

.operation_pill {
  display: flex;

  &-container {
    display: flex;
  }

  a {
    text-decoration: none;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  .pill-excess {
    @include font-size('12');
    color: #8a4df0;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    text-decoration: none;
    margin-right: 2px;
    margin-left: 4px;

    &--infringement {
      color: #8a4df0;
    }

    &--noiseevent {
      color: $arrival;
    }

    &--complaint {
      color: #5aaafa;
    }
  }
}

.operation_pill {
  a {
    text-decoration: none;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.operation-details_opType {
  margin-top: -3px;
}

.operation-details {
  .card {
    padding: 20px 10px;

    .description-list {
      padding: 0 0 0 0.8rem;

      &_title {
        margin-bottom: 0.4rem;
      }

      &_item {
        min-width: 5.2rem;

        margin: {
          right: $spacing-xs;
        }

        .inline-value {
          padding: 6px 3px;
        }

        .no-edit {
          padding-top: 6px;
        }
      }

      .type {
        .description-list_title {
          margin-bottom: 0.4rem;
        }
        .description-list_description {
          margin-left: -8px;
        }
      }
    }
  }
}

.filter-pca-location {
  display: inline-block;
  padding-left: 14px;

  &--category {
    font-family: $font-family-sans-serif;
    color: $ui-05;
    font-weight: 500 !important;
    font-size: 14px;
  }

  &--item {
    font-family: $font-family-sans-serif;
    color: $text-01;
    font-weight: 600 !important;
    font-size: 14px;
  }

  button {
    padding: 0 0 0 2px !important;
  }
}

@media screen and (max-width: 1439px) {
  .page-wrapper--landscape {
    @include collapse-correlated-pills;
  }
}

@media screen and (max-width: 1279px) {
  @include collapse-correlated-pills;
}
