@import '~@ems/client-design-system/scss/vars';

$map-height: 28rem !default;

:export {
  brand01: $brand-01;
  brandDarkened: $brand-darkened;
  arrival: $arrival;
  departure: $departure;
  overflight: $overflight;
  touchGo: $touch-go;
  airGeometry: $air-geometry;
  zoneGeometry: $zone-geometry;
  support03: $support-03;
  white: #ffffff;
  ui05: $ui-05;
  noisedb-30-35: $noisedb-30-35;
  noisedb-35-40: $noisedb-35-40;
  noisedb-40-45: $noisedb-40-45;
  noisedb-45-50: $noisedb-45-50;
  noisedb-50-55: $noisedb-50-55;
  noisedb-55-60: $noisedb-55-60;
  noisedb-60-65: $noisedb-60-65;
  noisedb-65-70: $noisedb-65-70;
  noisedb-70-75: $noisedb-70-75;
  noisedb-75-80: $noisedb-75-80;
  noisedb-80-plus: $noisedb-80-plus;
}
