.aircraft-mappings-table .table_cell.aircraftMappings-status {
  padding-left: $spacing-xs;
}

.full-circle {
  width: $layout-2xs;
  height: $layout-2xs;
  border-radius: 50%;
  background: $support-02;
}

.empty-circle {
  width: $layout-2xs;
  height: $layout-2xs;
  border-radius: 50%;
  display: inline-block;
  background: $ui-01;
  border: 1.5px solid $ui-03;
}

.half-filled {
  position: relative;
  @extend .empty-circle;
  border: 1.5px solid $support-02;

  &:after {
    position: absolute;
    content: '';
    width: $layout-2xs;
    height: $spacing-xs;
    background: $support-02;
  }
}

.upper-half-filled {
  @extend .half-filled;

  &:after {
    top: -2px;
    left: -2px;
    border-top-left-radius: 6.25rem;
    border-top-right-radius: 6.25rem;
  }
}

.lower-half-filled {
  @extend .half-filled;

  &:after {
    bottom: 0px;
    left: -2px;
    border-top-left-radius: 6.25rem;
    border-top-right-radius: 6.25rem;
    transform: rotate(180deg);
  }
}

.aircraft-mappings-header-text {
  @extend .font-styles;
  line-height: $spacing-lg;
  margin-bottom: $spacing-md;
}

.aircraft-mappings-proportions {
  display: flex;
  flex-wrap: wrap;
}

.lto-mappings-proportions,
.enroute-mappings-proportions {
  @extend .font-styles;

  margin-right: 1.875rem;
  padding-top: $spacing-xs;

  span {
    margin-right: 1.875rem;
  }
}

.aircraft-mappings-root {
  .table_wrapper {
    padding: 0px !important;
    margin-top: $spacing-md;
    margin-right: 0px !important;
  }
}

  .aircraft-mappings-grid {
    grid-template-columns:
      [percentMapped] 1fr
      [aircraft] 1fr
      [engine] auto
      [flights] 2fr
      [LTOEmissionModel] 2fr
      [enrouteEmissionModel] 2fr
  }

.aircraft-mappings-table {
  @extend .aircraft-mappings-grid;

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

}
