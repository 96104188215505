@import 'src/styles/vars.module.scss';

.load-more-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $layout-xs;
  background-color: $ui-02;

  button:first-child {
    margin-bottom: $layout-2xs;
  }
}
