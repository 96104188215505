@import 'src/styles/vars.module.scss';

$progress-color: $brand-01;

#nprogress {
  .bar {
    background: $progress-color;
  }

  .peg {
    box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
  }

  .spinner-icon {
    border-top-color: $progress-color;
    border-left-color: $progress-color;
  }
}
