@import 'src/styles/vars.module.scss';

.navbar {
  width: 100%;
  display: flex;
  padding: 16px;
  font-family: 'Inter', sans-serif;
  color: $inverse-02;

  .menu {
    display: flex;
    flex: 2 0 0;
    height: 40px;

    .navbar-logo {
      display: flex;

      .app-logo {
        width: 40px;
        height: 40px;
      }
    }

    .navbar-title {
      display: flex;
      margin-left: 15px;
      line-height: 40px;
    }

    .navbar-items {
      display: flex;
      margin-left: 30px;
      line-height: 40px;
    }

    .navbar-link {
      color: $brand-01;
      margin-left: 30px;
      line-height: 40px;
      text-decoration: none;
    }
  }

  .search {
    flex: 1 0 0;
    height: 40px;
  }
}

.navbar-children {
  padding-left: 72px;

  &.mobile {
    padding-top: 64px;
    padding-left: 0;
  }
}
