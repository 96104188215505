$text-01: #2d384d;
$ui-01: #ffffff;
$shadow-02: 0 2px 16px 0 rgba(39, 45, 51, 0.1);

.noise-popup {
  font-size: 0.75rem;
  width: fit-content !important;
  box-shadow: $shadow-02;

  .mapboxgl-popup-content {
    background-color: $ui-01 !important;
    padding: 3px 4px !important;
  }

  &--small {
    font-size: 0.625rem;

    & .noise-popup_db {
      font-weight: 500;
    }

    .mapboxgl-popup-content {
      padding: 2px 3px !important;
    }
  }

  &--large {
    font-size: 0.875rem;

    .mapboxgl-popup-content {
      padding: 4px 6px !important;
    }
  }

  &_db {
    color: $text-01;
    font-weight: 400;
    line-height: 0.75rem;
  }
}
