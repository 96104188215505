.u-flex-center {
  display: flex;
  align-items: center;
}

.status_icon {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-right: $spacing-xs;
}

.status_viewOnly {
  color: $text-01;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px;
}

.infringements_time {
  @include font-size('14');
}

.infringements-displayStatus {
  .bp3-switch {
    margin: 0;
    padding: 0;
  }
}

.infringements_noiseDescription {
  padding: 0 $spacing-sm;
}

.inf-regular-table {
  grid-template-columns:
    [selector] 64px
    [rule] minmax(80px, max-content)
    [time] minmax(45px, max-content)
    [type] auto
    [severity] var(--severity-width)
    [flight] minmax(90px, max-content)
    [category] 90px
    [model] auto
    [status] var(--status-width)
    [candidates] var(--candidates-width);
}

.inf-atc-table {
  grid-template-columns:
    [selector] 64px
    [rule] minmax(80px, max-content)
    [time] minmax(45px, max-content)
    [type] auto
    [severity] var(--severity-width)
    [flight] minmax(90px, max-content)
    [category] 90px
    [model] auto
    [vectored] max-content;
}

.inf-thin-table {
  grid-template-columns:
    [selector] 64px
    [rule] minmax(80px, max-content)
    [time] minmax(45px, max-content)
    [type] auto
    [severity] var(--severity-width)
    [flight] minmax(90px, max-content)
    [category] 90px
    [model] auto
    [status] var(--status-thin-width)
    [candidates] var(--candidates-width);
}

.table--slim .infringements-table--regular {
  @extend .inf-thin-table;
}

.infringements-table {
  @extend .inf-regular-table;

  &--atc {
    @extend .inf-atc-table;
  }

  &.table--loading .table_cell:hover .hover-only {
    opacity: 0;
  }

  .table_cell--item {
    button {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .hover-only {
    opacity: 0;
  }

  .rule_link {
    color: $brand-darkened;
    text-decoration: none;

    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: 300px;
  }

  .no-rule_link {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    max-width: 300px;
  }

  .infringements-displayRuleName {
    .table_cell--item {
      width: 100%;
    }
  }

  .cell-type {
    &--candidates {
      overflow-x: var(--candidates-overflow);
    }

    &--severity {
      overflow-x: hidden;
    }

    &--status {
      overflow-x: hidden;
    }

    &--vectored {
      justify-content: center;

      .table_cell--item {
        overflow: visible;
      }
    }

    &--record-title {
      font-weight: bold;
    }

    &--aircraft {
      overflow: visible;
      text-align: center;
      display: flex;
      justify-content: center;

      & .grid-cell--title {
        overflow: hidden;
        justify-content: center;
      }

      & .grid-cell--item {
        text-align: center;
        transform: translateX(-15%);
        overflow: visible;
      }

      .#{$prefix}-skeleton-text {
        margin: ($aircraft-icon-size - $grid-padding)/2;
        max-width: $aircraft-icon-size;
      }
    }

    &--time {
      color: $time-color;

      .table_cell--item {
        width: 100%;
      }
    }
  }

  .infringements-displayCategory .pos-rel {
    width: 100%;
  }

  .table-row_header .infringements-displayCategory .sort-icon {
    left: 100%;
  }
}

.overlay--bulk-edit {
  width: 350px;
}

.bulk-edit_cancel {
  margin-right: 0.5rem;
}

.candidates-text {
  color: #5aa700;
}

.noise--loading {
  padding: 10px;
}

.rule-details-grid {
  display: grid;
  row-gap: 1.5rem;
  column-gap: 0.25rem;
  margin-bottom: 1.5rem;

  &--5 {
    grid-template-columns: 30% repeat(4, 1fr);
  }

  &--2 {
    grid-template-columns: 40% 60%;
  }

  &_description {
    @include font-size('14');
    color: $text-01;
    font-weight: 600;
    overflow-x: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    min-height: 18px;
    line-height: 1.57;
  }
}

.rule-details_divider {
  border-top: 1px solid $ui-03;
  padding-top: 1rem;
}

.rule-details_cohort-title {
  margin-bottom: 1.125rem;
}
