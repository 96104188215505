.settings {
  &_layout {
    margin: 1rem auto;
  }

  &_switch {
    padding-left: 0.75rem;
  }
}

.settings-layout-content {
  @extend .layout_content;
  flex: auto;
  overflow: auto;
}

.settings-content {
  order: 2;
  width: 100%;
  position: relative;
  margin: $spacing-xl;
  flex: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.settings-sidebar {
  order: 1;
  width: 16rem;
  border-right: solid 1px #dfe3e6;
  @extend .font-styles;
  font-weight: bold;
  line-height: $spacing-lg;
  color: $text-01;
}

.sidebar-parent {
  display: block;
  flex-direction: column;
  position: relative;
  width: 14.375rem;
}

.sidebar-child {
  position: relative;

  .menubar-item-content {
    margin-left: $spacing-md;
  }
}

.settings-header {
  height: $spacing-xl;
}

.side-bar-menu {
  padding-top: 0px !important;
}

.settings-header-title {
  float: left;
  width: 'fit-content';
}

.settings-button {
  @extend .font-styles;
  float: right;
}

.settings-submit {
  @extend .settings-button;
  margin-right: 3rem;
  margin-left: 1rem;
  line-height: $spacing-lg;
  color: $inverse-01;
}

.settings-discard {
  @extend .settings-button;
  line-height: $spacing-xl;
  letter-spacing: -0.154px;
  color: $text-01;
}

.font-styles {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.temperature-info {
  @extend .font-styles;
  color: $text-01;
  margin-top: $spacing-xs;
}

.bp3-control-indicator {
  margin-right: $spacing-xs !important;
  margin-left: 0px !important;
}

.break-line {
  border: 1px solid $ui-03;
  margin: 0px;
}

.tma {
  @extend .font-styles;
  color: $text-01;
  width: 70%;
  padding-top: $spacing-sm;
}

.active-config {
  @extend .font-styles;
  color: #2f394c;
  margin-bottom: $spacing-3xs;
}

.scenario-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-md;
}

.manage-config {
  background-color: lightgrey;
  font-family: arial;
  margin-left: $spacing-xl;
  height: 80%;
  border-radius: $spacing-xs;
  display: inline-block;
  color: $text-01;
  height: fit-content;
  width: auto;
}

.dropdown {
  width: fit-content !important;
}

.select-config {
  display: 'flex';
  flex-direction: 'row';
}
